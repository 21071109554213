<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="people-fill" />
        {{ $t("users.title") }}
      </h1>
      <router-link to="/admin/users/new">
        <b-button variant="primary" data-cy="new-user-button" pill>
          <b-icon-person-plus class="mr-2" />
          {{ $t("users.newUserButton") }}
        </b-button>
      </router-link>
    </div>

    <b-card border-variant="light" class="mt-4 shadow-sm card-rounded">
      <b-form @submit.prevent="search">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              autocomplete="off"
              data-cy="search-input"
              :placeholder="$t('typeToSearch')"
              type="search"
            />

            <b-input-group-append>
              <b-button data-cy="user-clear-search-button" @click="clearFilter">
                <b-icon-x />
              </b-button>

              <b-button
                variant="primary"
                data-cy="user-search-button"
                type="submit"
              >
                {{ $t("search") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-form>

      <div class="overflow-auto">
        <b-table
          :busy="loading"
          :current-page="currentPage"
          :fields="fields"
          :head-variant="'light'"
          :items="users"
          :per-page="0"
          class="mb-0"
          data-cy="admin-user-table"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("loading") }}</strong>
            </div>
          </template>

          <template #cell(name)="data">
            <router-link :to="'/admin/users/' + data.item.id">
              {{ data.value }}
            </router-link>
          </template>

          <template #cell(organization)="data">
            <router-link
              v-if="data.item.tenantId"
              :to="'/admin/organization/' + data.item.tenantId"
            >
              {{ data.item.tenant.displayName }}
            </router-link>

            <div v-else>{{ data.value }}</div>
          </template>

          <template #cell(enabled)="data">
            <b-badge :variant="data.value ? 'primary' : 'secondary'">
              {{ data.value ? "ENABLED" : "DISABLED" }}
            </b-badge>
          </template>
          <template #cell(empty)="data">
            <div class="d-flex justify-content-end">
              <b-button
                :data-cy="`edit-user-button-${data.item.name}`"
                class="ml-2"
                variant="primary"
                pill
                size="sm"
                @click="goToEditUser(data.item.id)"
              >
                <b-icon-pencil />
              </b-button>
              <b-button
                v-b-modal.modal-center
                :data-cy="`delete-user-button-${data.item.name}`"
                :disabled="data.item.email === disableDelete"
                class="ml-2"
                pill
                size="sm"
                variant="outline-danger"
                @click="deleteModal(data.item.id)"
              >
                <b-icon-trash />
              </b-button>
            </div>
          </template>
        </b-table>
      </div>

      <b-pagination
        v-if="totalElements > pageSize"
        v-model="currentPage"
        :per-page="pageSize"
        :total-rows="totalElements"
        align="right"
        class="mb-0 mt-3"
        data-cy="pagination"
        first-text="First"
        last-text="Last"
        next-text="Next"
        prev-text="Prev"
      />

      <b-card-text
        v-if="users.length === 0 && !loading"
        class="text-center my-4"
      >
        {{ $t("users.emptyTable") }}
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Users",
  data() {
    return {
      users: [],
      pageSize: 10,
      totalElements: null,
      currentPage: 1,
      loading: true,
      fields: [
        { key: "name", label: this.$i18n.t("users.username") },
        { key: "organization", label: "Organization" },
        { key: "email", label: "E-mail Address" },
        { key: "enabled", label: "Status" },
        { key: "empty", label: "" },
      ],
      disableDelete: this.$store.getters.email,
      filter: null,
    };
  },
  mounted() {
    this.userProvider();
  },
  methods: {
    // navigate to edit user page
    goToEditUser(userName) {
      this.$router.push(`/admin/users/${userName}`);
    },

    deleteModal(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure that you want to delete this user?", {
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) this.deleteUser(id);
        })
        .catch((err) => {
          if (err.response) console.log(err.response);
          // An error occurred
        });
    },

    // delete a user
    async deleteUser(id) {
      try {
        await this.$http.delete(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/accounts/${id}`
        );
        this.$bvToast.toast("User deleted!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
        this.currentPage = 1;
        await this.userProvider();
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    // get users
    async userProvider() {
      this.loading = true;
      try {
        const {
          data: { content, page },
        } = await this.$http.get(this.$cfg.BASE_IDENTITY_URL + "/v0/accounts", {
          params: {
            page: this.currentPage - 1,
            pageSize: this.pageSize,
            search: this.filter,
          },
        });

        // split username from org name
        content.forEach((user) => {
          const split = user.name.split("/");
          user.name = split[1];
          user.organization = split[0];
        });

        this.totalElements = page.totalElements;
        this.users = content;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalElements = filteredItems.length;
      this.currentPage = 1;
    },

    search() {
      this.loading = true;
      this.currentPage = 1;
      this.userProvider();
    },

    clearFilter() {
      this.filter = null;
      this.currentPage = 1;
      this.userProvider();
    },
  },
  watch: {
    currentPage: {
      handler: function () {
        this.userProvider();
      },
    },
  },
};
</script>
